<template>
  <div class="body">
    <div class="top-bg">
      <div class="top-cont flex text-center">
        <div class="top-title">乐活通火热招募中</div>
        <div class="top-text">优秀服务商集合地，点金共赢就等你来</div>
        <div class="btn top-btn" v-if="false">立即入驻</div>
      </div>
    </div>
    <div class="heart merchant-title">
      <div class="flex title-box">
        <div class="tag-title">乐活通入驻流程 开店前先看</div>
        <el-image
          @click="handleVisible"
          class="pic point"
          :src="require('@/assets/img/merchant_8.png')"
          fit="fill"
        ></el-image>
      </div>
      <div class="flex box-card">
        <div class="item">
          <svg class="icon icon-pic" aria-hidden="true">
            <use xlink:href="#icon-dengluxiaochengxu"></use>
          </svg>
          <div class="name"><span>1</span>登录小程序</div>
          <div class="cont">微信扫描下方二维码进入国美家“乐活通”小程序。</div>
        </div>
        <div class="arrow"></div>
        <div class="item">
          <svg class="icon icon-pic" aria-hidden="true">
            <use xlink:href="#icon-tijiaoziliao"></use>
          </svg>
          <div class="name"><span>2</span>提交资料</div>
          <div class="cont">
            <div>
              1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
            </div>
            <div>2）提交审核</div>
          </div>
        </div>
        <div class="arrow"></div>
        <div class="item">
          <svg class="icon icon-pic" aria-hidden="true">
            <use xlink:href="#icon-dengdaishenhe"></use>
          </svg>
          <div class="name"><span>3</span>等待审核</div>
          <div class="cont">
            <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
            <div>2）商家可查询入驻审核进度，及接收入驻结果的短信通知</div>
          </div>
        </div>
        <div class="arrow"></div>
        <div class="item">
          <svg class="icon icon-pic" aria-hidden="true">
            <use xlink:href="#icon-qianshuhetong"></use>
          </svg>
          <div class="name"><span>4</span>签署合同</div>
          <div class="cont">
            1）待入驻资料审核通过之后，商户可登陆小程序签署合同，支付入驻费，开始经营
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey">
      <div class="heart merchant-title">
        <div class="title-box title-box1">
          <div class="tag-title">期待你加入</div>
        </div>
        <div class="flex box-card">
          <div class="code-left downapp">
            <el-image
              class="pic"
              :src="require('@/assets/img/merchant_16.png')"
              fit="fill"
            ></el-image>
            <div class="box" @click="downloadApp">手机app下载</div>
          </div>
          <div class="code-box code-box1">
            <div class="code-title">乐活通雇主小程序</div>
            <el-image
              class="code-pic"
              :src="require('@/assets/img/boss.jpg')"
              fit="fill"
            ></el-image>
            <div class="code-text">扫描二维码下载</div>
          </div>
          <div class="code-box">
            <div class="code-title">乐活通服务商小程序</div>
            <el-image
              class="code-pic"
              :src="require('@/assets/img/worker.jpg')"
              fit="fill"
            ></el-image>
            <div class="code-text">扫描二维码下载</div>
          </div>
        </div>
      </div>
    </div>
    <div class="heart merchant-title merchant-title1">
      <div class="title-box title-box2">
        <div class="tag-title">更多入驻帮助</div>
      </div>
      <div class="steps">
        <div class="steps-title flex">
          <el-image
            class="pic steps-title-pic"
            :src="require('@/assets/img/merchant_10.png')"
            fit="fill"
          ></el-image>
          <div class="steps-title-text">第一步：准备相关资料</div>
        </div>
        <div class="steps-cont">
          <div class="steps-cont-line fl"></div>
          <div class="steps-cont-card flex">
            <div class="item">
              <div class="name"><span>1</span>实名认证相关</div>
              <div class="cont">
                微信扫描下方二维码进入国美家“乐活通”小程序。
              </div>
            </div>
            <div class="item">
              <div class="name"><span>2</span>公司认证相关</div>
              <div class="cont">
                <div>
                  1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                </div>
                <div>2）按照小程序内的要求上传相关资质照片及填写相关资料</div>
              </div>
            </div>
            <div class="item">
              <div class="name"><span>3</span>开户行信息</div>
              <div class="cont">
                <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                <div>2）商家可查询入驻审核进度，及接收入驻结果的短信通知</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="steps">
        <div class="steps-title flex">
          <el-image
            class="pic steps-title-pic"
            :src="require('@/assets/img/merchant_11.png')"
            fit="fill"
          ></el-image>
          <div class="steps-title-text">第二步：入驻申请</div>
        </div>
        <div class="steps-cont">
          <div class="steps-cont-line fl"></div>
          <div class="steps-cont-card flex">
            <div class="item">
              <div class="name"><span>1</span>打开乐活通</div>
              <div class="cont">
                微信扫描下方二维码进入国美家“乐活通”小程序。
              </div>
            </div>
            <div class="item">
              <div class="name"><span>2</span>填写资料</div>
              <div class="cont">
                <div>
                  1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                </div>
                <div>2）按照小程序内的要求上传相关资质照片及填写相关资料</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="steps">
        <div class="steps-title flex">
          <el-image
            class="pic steps-title-pic"
            :src="require('@/assets/img/merchant_12.png')"
            fit="fill"
          ></el-image>
          <div class="steps-title-text">第三步：等待招商审核</div>
        </div>
        <div class="steps-cont">
          <div class="steps-cont-line fl"></div>
          <div class="steps-cont-card flex">
            <div class="item">
              <div class="name"><span>1</span>招商审核</div>
              <div class="cont">
                微信扫描下方二维码进入国美家“乐活通”小程序。
              </div>
            </div>
            <div class="item">
              <div class="name"><span>2</span>查看审核结果</div>
              <div class="cont">
                <div>
                  1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                </div>
                <div>2）按照小程序内的要求上传相关资质照片及填写相关资料</div>
              </div>
            </div>
            <div class="item">
              <div class="name"><span>3</span>开户行信息</div>
              <div class="cont">
                <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                <div>2）商家可查询入驻审核进度，及接收入驻结果的短信通知</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="steps">
        <div class="steps-title flex">
          <el-image
            class="pic steps-title-pic"
            :src="require('@/assets/img/merchant_13.png')"
            fit="fill"
          ></el-image>
          <div class="steps-title-text">第四步：签署合同及支付入驻费</div>
        </div>
        <div class="steps-cont">
          <div class="steps-cont-card flex">
            <div class="item">
              <div class="name"><span>1</span>签署合同</div>
              <div class="cont">
                微信扫描下方二维码进入国美家“乐活通”小程序。
              </div>
            </div>
            <div class="item">
              <div class="name"><span>2</span>支付入驻费</div>
              <div class="cont">
                <div>
                  1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                </div>
                <div>2）按照小程序内的要求上传相关资质照片及填写相关资料</div>
              </div>
            </div>
            <div class="item">
              <div class="name"><span>3</span>打印合同并盖章</div>
              <div class="cont">
                <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                <div>2）商家可查询入驻审核进度，及接收入驻结果的短信通知</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 入驻视频 -->
    <MerchantVideo
      :src="src"
      :dialog-visible="dialogVisible"
      @closeDialog="handleVisible"
    />
  </div>
</template>

<script>
import MerchantVideo from "@/components/MerchantVideo.vue";
export default {
  data() {
    return {
      dialogVisible: false,
      src: "http://mp4.vjshi.com/2021-05-13/d0e55b2f8bdd2c536394ef93e1a3161a.mp4",
    };
  },
  // 注册子组件
  components: {
    MerchantVideo,
  },
  created() {},
  mounted() {},
  methods: {
    handleVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    downloadApp() {
      this.$router.push("downLoadApp");
    },
  },
  computed: {},
};
</script>


<style scoped lang="scss">
.bg-grey {
  padding: 20px 0;
}
.top-bg {
  background: url("~@/assets/img/merchant_4.png") no-repeat center 0;
}

.downapp {
  //   .pic {
  .el-image__inner {
    width: 83%;
    margin: 0 auto;
  }
  //   }

  .box {
    width: 280px;
    height: 58.8px;
    background-image: linear-gradient(270deg, #ff4242 0%, #ff4a2d 100%);
    box-shadow: 0px 10px 15px 0px rgba(255, 194, 194, 1);
    text-align: center;
    color: #fff;
    line-height: 58.8px;
    border-radius: 30px;
    margin: 0 auto;
	cursor: pointer;
  }
}
.code-pic{
					width: 180px !important;
					height: 180px !important;
					margin: 70px auto 25px;
				}
</style>
<style >
.downapp .el-image__inner {
  width: 83%;
  margin: 0 auto;
}
</style>